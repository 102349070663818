const Data = [
  {
    id: 1,
    category: "education",
    icon: "icon-graduation",
    year: "Aug 2022 - May 2026",
    title: "B.Sc in Computer Science",
    institution: "Texas Tech University",
    institutionUrl: "https://www.ttu.edu/",
    desc: "Relevant coursework: Computational Thinking with Data Science, Programming Principles, Data Structures, Discrete Computational Structures, Object-Oriented Programming, Linear Algebra, Calculus I - III.",
  },
  {
    id: 2,
    category: "education",
    icon: "icon-graduation",
    year: "Aug 2019 - Jul 2020",
    title: "B.Sc in Accounting & Finance",
    institution: "Foreign Trade University",
    institutionUrl: "https://english.ftu.edu.vn/",    
    desc: "Relevant coursework: Probability Theory and Mathematical Statistics, Financial Math, Microeconomics, Macroeconomics, Accounting Principles.",
  },
  {
    id: 3,
    category: "experience",
    icon: "icon-briefcase",
    year: "Aug 2024 - Present",
    title: "Student Tutor",
    institution: "Texas Tech University - Learning Center",
    institutionUrl: "https://www.depts.ttu.edu/soar/LC/",
    desc: "Tutoring courses: Linear Algebra, Programming Principles, Data Structures, Object-Oriented Programming.",
  },
  {
    id: 4,
    category: "experience",
    icon: "icon-briefcase",
    year: "May 2024 - Present",
    title: "Undergraduate Research Assistant",
    institution: "Wu Research Group - TTU",
    institutionUrl: "https://www.wunanolab.com/",
    desc: "Research and develope high-accuracy ML models to classify ECG signals for various types of cardiac diseases.",
  },
  {
    id: 5,
    category: "experience",
    icon: "icon-briefcase",
    year: "May 2024 – Jul 2024",
    title: "Data Science Intern",
    institution: "Texas Tech University Advising",
    institutionUrl: "https://www.depts.ttu.edu/advising/",
    desc: "Analyzed courses and academic performance of 5500+ students from previous semesters to provide personalized course recommendations for student success.",
  },
];

export default Data;
